import portfolio from '@/services/paDealing/portfolioService'

export default {
  namespaced: true,
  state: {
    portfolioRecord: [],
  },
  getters: {
    getPortfolioRec: state => state.portfolioRecord,
  },
  mutations: {
    PORTFOLIO(state, payload) {
      state.portfolioRecord = payload
    },
  },
  actions: {
    async fetchPortfolio({ commit }, companyId) {
      commit('PORTFOLIO', [])
      const data = await portfolio.fetchPortfolio(companyId)
      commit('PORTFOLIO', data)
    },
  },
}
