import Vue from 'vue'
import VueRouter from 'vue-router'
import amlKyc from './modules/aml-kyc'
import companies from './modules/companies'
import complianceReport from './modules/compliance-report'
import crsFatca from './modules/crs-fatca'
import paDealing from './modules/pa-dealing'
import paRequest from './modules/pa-request'
import training from './modules/training'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      return { name: 'companies' }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Register.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/ForgotPassword.vue'),
    // meta: {
    //   layout: 'blank',
    //   resource: 'Public',
    //   redirectIfLoggedIn: true,
    // },
  },
  ...paDealing,
  ...companies,
  ...amlKyc,
  ...complianceReport,
  ...training,
  ...crsFatca,
  {
    path: '*',
    redirect: 'error-404',
  },
  ...paRequest,
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!localStorage.getItem('accessToken')
  // * if not logged-in goto login page
  if (to.name !== 'auth-login' && to.name !== 'pa-request' && !isLoggedIn) {
    next({ name: 'auth-login' })
  }
  //* if logged-in redirect login pages to index
  else if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  } else next()
})

export default router
