const complianceReport = [
  {
    path: '/compliance-report/report',
    name: 'compliance-report',
    component: () => import('@/views/compliance-report/Report.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/compliance-report/tracker',
    name: 'compliance-report-tracker',
    component: () => import('@/views/compliance-report/ReportTracker.vue'),
    meta: {
      layout: 'content',
    },
  },
]

//Statements

export default complianceReport
