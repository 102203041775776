import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"auth-inner"},[_c(VCard,{staticClass:"auth-card"},[_c(VCardTitle,{staticClass:"d-flex align-center justify-center py-7"},[_c('h2',{staticClass:"text-2xl font-weight-semibold text-uppercase"},[_vm._v("PA Request")])]),_c(VCardText,[_c(VForm,{ref:"loginForm",on:{"submit":function($event){$event.preventDefault();return _vm.handleFormSubmit.apply(null, arguments)}}},[_c('ac-input',{staticClass:"mb-6",attrs:{"outlined":"","label":"Email","placeholder":"email","validation-rules":[_vm.validators.required, _vm.validators.emailValidator],"hide-details":"auto"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('ac-input',{staticClass:"mb-6",attrs:{"outlined":"","type":_vm.isPasswordVisible ? 'text' : 'password',"label":"Password","placeholder":"Password","append-icon":_vm.isPasswordVisible ? _vm.icons.mdiEyeOffOutline : _vm.icons.mdiEyeOutline,"validation-rules":[_vm.validators.required],"hide-details":"auto"},on:{"appendClick":function($event){_vm.isPasswordVisible = !_vm.isPasswordVisible}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('ac-select',{staticClass:"company-select",attrs:{"label":"Type of Request","items":[
                { id: 'Public Trade Request', name: 'Public Trade Request' } ],"validation-rules":[function (v) { return !!v || 'Field is required'; }]},model:{value:(_vm.details),callback:function ($$v) {_vm.details=$$v},expression:"details"}}),_c('ac-button',{staticClass:"mt-6",attrs:{"block":"","labe":"","color":"primary","type":"submit","loading":_vm.loading,"disabled":_vm.loading}},[_vm._v(" Submit ")])],1)],1),_c(VCardText,{staticClass:"d-flex align-center justify-center flex-wrap mt-1"},[_c('router-link',{attrs:{"to":{ name: 'auth-register-v1' }}},[_vm._v(" Back To Login Page ")])],1)],1)],1),_c('img',{staticClass:"auth-mask-bg",attrs:{"src":require("@/assets/images/svg/wave.svg")}}),_c(VSnackbar,{attrs:{"color":"error","text":"","bottom":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var attrs = ref.attrs;
return [_c('ac-icon-btn',_vm._b({attrs:{"btn-class":"me-2","icon":_vm.icons.mdiClose,"color":"error","no-tooltip":""},on:{"click":function($event){_vm.errorMessage = null}}},'ac-icon-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.showErrorMessage),callback:function ($$v) {_vm.showErrorMessage=$$v},expression:"showErrorMessage"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }