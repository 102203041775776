const amlKyc = [
  {
    path: '/aml-kyc',
    name: 'aml-kyc',
    component: () => import('@/views/aml-kyc/AmlKycLogin.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/aml-kyc/reset-password',
    name: 'reset-password',
    component: () => import('@/views/resetpassword.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/aml-kyc/customer-database',
    name: 'customer-database',
    component: () => import('@/views/aml-kyc/CustomerDatabase.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default amlKyc
