import paRequestService from '@/services/paDealing/paRequestService'

export default {
  namespaced: true,
  state: {
    paRequests: { totalRecords: 0, records: [] },
  },
  getters: {
    getPaRequests: state => state.paRequests.records,
    getPaRequestsTotal: state => state.paRequests.totalRecords,
  },
  mutations: {
    SET_PA_REQUESTS(state, payload) {
      state.paRequests = payload
    },
  },
  actions: {
    async fetchPaRequests({ commit }, { companyId, payload }) {
      commit('SET_PA_REQUESTS', { totalRecords: 0, records: [] })
      const data = await paRequestService.fetchPaRequests(companyId, payload)
      commit('SET_PA_REQUESTS', data)
    },
    async fetchAllPaRequests({ commit }, { companyId, payload }) {
      const data = await paRequestService.fetchAllPaRequests(companyId, payload)
      return data
    },
    async fetchPdfDownloadLink({ commit }, payload) {
      const data = await paRequestService.fetchPdfDownloadLink(payload)
      return data
    },
    async removePaRequestRecord({ commit }, payload) {
      const data = await paRequestService.removePaRequestRecord(payload)
      return data
    },
    async fetchCompanyInitialApproverOnly({ commit }, payload) {
      const data = await paRequestService.fetchCompanyInitialApproverOnly(payload)
      return data
    },
  },
}
