import requestVarification from '@/views/pa-request/PaRequest.vue'
const paRequest = [
  {
    path: '/pa-request',
    name: 'pa-request',
    component: requestVarification,
    meta: {
      layout: 'content',
    },
  },
]

export default paRequest
