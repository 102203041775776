import transformers from '@/transformers'
import axios from '@axios'

const { createError, createSuccessMsg, mapCompanies } = transformers

export default {
  async fetchCompanies() {
    try {
      const { data } = await axios.get(`/companies/`)
      const { totalRecords, records } = data

      const transformedData = mapCompanies(records)

      return { totalRecords, records: transformedData }
    } catch (error) {}
  },
  async toggleCompanyActiveStatus(companyID) {
    try {
      const { status, data } = await axios.put(`/companies/active-status/${companyID}`)

      return status === 200 ? createSuccessMsg(data) : createError('Something went wrong!')
    } catch (error) {
      console.error(error)
      return createError('Something went wrong!')
    }
  },
  async createCompany(payload) {
    try {
      const { status } = await axios.post(`/companies`, payload)

      return status === 200
        ? createSuccessMsg('Company added Successfully')
        : createError('Something went wrong!')
    } catch (error) {
      return error.response
        ? createError(error.response.data)
        : createError('Something went wrong!')
    }
  },
  async removeCompany(companyID) {
    try {
      const { status } = await axios.delete(`/companies/${companyID}`)

      return status === 200
        ? createSuccessMsg('Company removed Successfully')
        : createError('Something went wrong!')
    } catch (error) {
      return error.response
        ? createError(error.response.data)
        : createError('Something went wrong!')
    }
  },
  async updateCompanySubscription(payload) {
    try {
      const { id, type } = payload

      const { status } = await axios.put(`/companies/subscriptions/${id}/${type}`, payload)

      return status === 200
        ? createSuccessMsg('Company subscription updated Successfully')
        : createError('Something went wrong!')
    } catch (error) {
      console.error(error)
      return createError('Something went wrong!')
    }
  },
}
