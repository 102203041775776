<template>
  <v-tooltip v-if="!disabled" :bottom="bottom" max-width="160px" :disabled="noTooltip">
    <template #activator="{ on, attrs }">
      <v-btn
        v-if="icon"
        :class="btnClass"
        fab
        x-small
        v-bind="attrs"
        v-on="on"
        :color="color"
        :href="href"
        :target="target"
        :loading="loading"
        @click.prevent="$emit('click')"
      >
        <v-icon :size="iconSize">
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <span class="text-caption font-weight-bold">{{ text }}</span>
  </v-tooltip>
  <v-tooltip v-else :bottom="bottom" max-width="160px" :disabled="noTooltip">
    <template #activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-btn v-if="icon" :class="btnClass" fab x-small :color="color" disabled>
          <v-icon :size="iconSize">
            {{ icon }}
          </v-icon>
        </v-btn>
      </span>
    </template>
    <span class="text-caption font-weight-bold">{{ text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'AcIconBtn',
  props: {
    text: {
      type: String,
      default: '',
    },
    btnClass: {
      type: String,
      default: '',
    },
    bottom: {
      type: Boolean,
      default: true,
    },
    noTooltip: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: '18',
    },
    color: {
      type: String,
      default: '',
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped></style>
