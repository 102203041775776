import moment from 'moment-timezone'

const mapCompanies = records => {
  if (records?.length) {
    return records.map(record => {
      const adminEmails = generateAdminEmails(record.admin)
      const licenses = generateLicenses(record.licenses)
      const incorporationDate = generateIncorporationDate(record.incorporationDate)

      return {
        id: record._id,
        name: record.name,
        adminEmails,
        admin: record.admin,
        ceNumber: record.cenumber,
        subscriptions: record.subscriptions,
        licenses,
        licensesAlt: record.licenses,
        isActive: !record.deactivated,
        financialYearEnd: record.financialYearEnd || null,
        incorporationDate,
      }
    })
  } else {
    return []
  }
}

export default {
  mapCompanies,
}

const generateAdminEmails = list => {
  const emails = list.filter(record => !record.user.deactivated).map(record => record.user.email)
  return emails.length ? emails : ['—']
}

const generateLicenses = list => {
  const licenses = list.map(record => record.type)
  return licenses
}

const generateIncorporationDate = date => {
  return date ? moment(date).format('MM/DD/YY') : null
}
