import sharedTransformers from './sharedTransformers'
const { createError, createSuccessMsg, createSuccessResponse, mapCompaniesOptions } =
  sharedTransformers

import staffTransformers from './staffTransformers'
const { mapCompanyStaff, mapAccounts } = staffTransformers

import statementsTransformers from './statementsTransformers'
const { mapStatements, mapBrokerStatements } = statementsTransformers

import restrictedWatchListServiceTransformers from './restrictedWatchListServiceTransformers'
const { mapRestrictedWatchList } = restrictedWatchListServiceTransformers

import paRequestTransformer from './paRequestTransformers'
const { mapDownloadPaRequests, mapPaRequestRecords } = paRequestTransformer

import companiesTransformer from './companiesTransformers'
import transactionRules from './transactionRulesTransformers'
const { mapTransactionRules } = transactionRules
const { mapCompanies } = companiesTransformer

import accountNumbersTransformers from './accountNumbers'
const { mapAccountNumbers } = accountNumbersTransformers

import approvalTransformers from './approvalTransformers'
const { approvalListTransformer } = approvalTransformers

export default {
  createSuccessResponse,
  createSuccessMsg,
  createError,
  mapCompaniesOptions,
  mapCompanyStaff,
  mapAccounts,
  mapRestrictedWatchList,
  mapStatements,
  mapBrokerStatements,
  mapDownloadPaRequests,
  mapAccountNumbers,
  approvalListTransformer,
  mapPaRequestRecords,
  mapTransactionRules,
  mapCompanies,
}
