import transformers from '@/transformers'
import axios from '@axios'

const {
  createError,
  createSuccessResponse,
  createSuccessMsg,
  mapDownloadPaRequests,
  mapPaRequestRecords,
} = transformers

export default {
  async fetchPaRequests(companyID, payload) {
    try {
      console.log({ payload })
      const params = {
        limit: payload.limit || 10,
        skipData: payload.skipData || 0,
        showInactiveUsers: payload.showInactiveUsers || false,
        search: payload.search,
      }

      const { data } = await axios.get(`/pa-requests/${companyID}/true/`, { params })
      const { totalItems: totalRecords, docs } = data

      const records = mapPaRequestRecords(docs)

      return { totalRecords, records }
    } catch (error) {}
  },
  async fetchAllPaRequests(companyID, payload) {
    try {
      const params = {
        limit: payload.limit || 10,
        skipData: 0,
        showInactiveUsers: payload.showInactiveUsers || false,
        search: payload.search,
      }

      const { status, data } = await axios.get(`/pa-requests/${companyID}/true/`, { params })
      const { docs } = data

      if (status === 200) {
        const records = mapDownloadPaRequests(docs)
        return createSuccessResponse(records)
      }
    } catch (error) {
      const { status } = error.response

      const errorMessage = status
        ? `${status}: Something went wrong while fetching PA Request List!`
        : 'Something went wrong!'
      return createError(errorMessage)
    }
  },
  async fetchPdfDownloadLink(payload) {
    try {
      const { status, data } = await axios.post(`pa-requests/download-pdf`, payload)
      if (status === 200) {
        window.open(data, '_blank')
        return createSuccessMsg('Please check your downloads.')
      }
    } catch (error) {
      console.error(error)
      return createError('Something went wrong!')
    }
  },
  async removePaRequestRecord(id) {
    try {
      const { status } = await axios.delete(`pa-requests/${id}`)

      return status === 200
        ? createSuccessMsg('Staff removed Successfully')
        : createError('Something went wrong!')
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async fetchCompanyInitialApproverOnly(companyID) {
    try {
      const { status, data } = await axios.get(`pa-requests/company/${companyID}`)
      return data
    } catch (error) {
      console.error(error)
      return createError('Something went wrong!')
    }
  },
}
