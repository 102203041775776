import approvalService from '@/services/approvals/approvalService'
import restrictedAndWatchListService from '@/services/paDealing/restrictedAndWatchListService'

export default {
  namespaced: true,
  state: {
    // ! fix: approval should be object and properties should be same as ref store(ref: pa-request.js)
    approval: { totalLength: 0, data: [] },
    accounts: [],
    security: [],
  },
  getters: {
    getApprovals: state => state.approval.data,
    approvalsCount: state => state.approval.totalLength,
    accountNumbers: state => state.accounts,
    securityList: state => state.security,
  },
  mutations: {
    SET_APPROVAL(state, payload) {
      state.approval = payload
    },
    SET_ACCOUNT(state, payload) {
      state.accounts = payload
    },

    SET_SECURITY(state, payload) {
      state.security = payload
    },
  },
  actions: {
    // ! fix: remove redundant comments
    // * staff api
    async createApproval({ commit }, payload) {
      const response = await approvalService.createApproval(payload)
      return response
    },

    async updateApproval({ commit }, payload) {
      const response = await approvalService.updateApproval(payload)
      return response
    },

    async downloadApproval({ commit }, payload) {
      const response = await approvalService.downloadApproval(payload)
      return response
    },

    async fetchSecurityList({ commit }, query) {
      commit('SET_SECURITY', [])
      const { success, data } = await restrictedAndWatchListService.fetchSecurityList(query)
      if (success) {
        commit('SET_SECURITY', data)
      } else {
        return data
      }
    },

    async getApproval({ commit }, payload) {
      commit('SET_APPROVAL', { totalLength: 0, data: [] })
      const approvalData = await approvalService.getApproval(payload)
      const { totalLength, data } = approvalData
      commit('SET_APPROVAL', { totalLength, data })
    },
    async removeApproval(state, id) {
      const response = await approvalService.removeApproval(id)
      return response
    },

    async getAccountNumbers({ commit }, companyId) {
      const response = await approvalService.getAccountNumbers(companyId)
      commit('SET_ACCOUNT', response)
    },
  },
}
