import transformers from '@/transformers'
import axios from '@axios'
const { createError, createSuccessResponse, createSuccessMsg } = transformers

export default {
  // * staff module
  async fetchCompanyStaff(companyID) {
    try {
      const { data } = await axios.get(`/staff/list/${companyID}?trim=true`)
      return transformers.mapCompanyStaff(data)
    } catch (error) {}
  },
  async createStaff(payload) {
    try {
      const { status } = await axios.post('/staff', payload)
      return status === 201
        ? createSuccessMsg('Staff added Successfully')
        : createError('Something went wrong!')
    } catch (error) {
      const { response } = error
      return response && response.status === 400
        ? createError('Email already in use!')
        : createError('Something went wrong!')
    }
  },
  async removeCompanyStaff(id) {
    try {
      const { status } = await axios.delete(`/removeStaffUser/${id}`)
      return status === 200
        ? createSuccessMsg('Staff removed Successfully')
        : createError('Something went wrong!')
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async updateApprover(payload) {
    try {
      const { userID, companyID, LoggedUserId, editUser } = payload
      const { status } = await axios.put(`/staff/save-approver/${companyID}/${userID}`, {
        LoggedUserId,
        editUser,
      })

      return status === 200
        ? createSuccessMsg('Staff approver status updated successfully')
        : createError('Something went wrong!')
    } catch (error) {
      return createError('Something went wrong!')
    }
  },

  async fetchAccounts(companyID) {
    try {
      const { data } = await axios.get(`/staff/account-numbers/${companyID}?populateUserId=true`)
      return transformers.mapAccounts(data)
    } catch (error) {}
  },
  async createAccountNumber(payload) {
    try {
      const { status } = await axios.post(`/staff/account-numbers`, payload)

      return status === 200
        ? createSuccessMsg('Account number added Successfully')
        : createError('Something went wrong!')
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async updateAccountNumber(payload) {
    try {
      const { status } = await axios.put(
        `/staff/account-numbers/${payload.userID}`,
        payload.content,
      )

      return status === 200
        ? createSuccessMsg('Account updated')
        : createError('Something went wrong!')
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async removeAccountNumber(id) {
    try {
      const { status } = await axios.delete(`/staff/account-numbers/${id}`)

      return status === 200
        ? createSuccessMsg('Account number removed Successfully')
        : createError('Something went wrong!')
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async accountsUploadFile(payload) {
    try {
      const { data } = await axios.post(
        `upload-file/accountnumbers/${payload.companyID}?Tool=PA Dealing&Section=Account Numbers`,
        payload.file,
      )
      return createSuccessResponse(data)
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async accountsRemoveFile(payload) {
    try {
      const { status } = await axios.delete(`delete-file/accountnumbers`, {
        data: payload,
      })
      return status === 200
        ? createSuccessMsg('File Removed Successfully')
        : createError(`${status}  Something went wrong!`)
    } catch (error) {
      return createError('Something went wrong!')
    }
  },

  async removeStaffById(id) {
    try {
      const { status } = await axios.delete(`/staff/${id}`)

      return status === 200
        ? createSuccessMsg('File Removed Successfully')
        : createError(`${status}  Something went wrong!`)
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
}
