import store from '@/store'
import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

axios.interceptors.response.use(
  function (response) {
    response.body = response.data
    return response
  },
  function (error) {
    // if (error.response?.status === 401) {
    //   console.log('Unauthorized Request')
    //   UnauthorizedRequestRedirect()
    // }
    if (error.response?.status === 404 && error.response.data === 'Invalid token, user not found') {
      UnauthorizedRequestRedirect()
    }
    return Promise.reject(error)
  },
)

axios.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`
    }

    if (!config.url.includes('user/login')) {
      console.info(config.url)

      const userIsLoggedIn = store.getters['app/loggedInUser']
      if (!userIsLoggedIn) {
        console.error('LOGIN')
        UnauthorizedRequestRedirect()
      }
    }

    return config
  },
  error => Promise.reject(error),
)

export default axios

const UnauthorizedRequestRedirect = () => {
  localStorage.removeItem('accessToken')
  window.location.replace(`${window.location.origin}/`)
}
