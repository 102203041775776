import transformers from '@/transformers'
import axios from '@axios'

const { mapCompaniesOptions } = transformers

export default {
  // * auth
  async login(payload) {
    try {
      const { data } = await axios.post('/user/login', payload)
      console.log(data)
      const { tokens, user } = data
      return {
        success: true,
        token: tokens.access.token,
        // tokenType: tokens.token_type,
        user,
      }
    } catch (error) {
      if (error.response.data.message) {
        return {
          success: false,
          message: error.response.data.message,
        }
      }
    }
  },

  async fetchCompaniesOptions() {
    try {
      const { data } = await axios.get('/companies/options')

      const transformedData = mapCompaniesOptions(data)
      return transformedData
    } catch (error) {
      console.error('fetchCompaniesOptions:', error)
    }
  },
}
