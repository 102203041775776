import transformers from '@/transformers'
import axios from 'axios'

const { createError, createSuccessMsg, mapTransactionRules } = transformers

export default {
  async fetchTransactions(companyId) {
    try {
      const data = await axios.get(`/transaction-rules/?company=${companyId}`)
      return mapTransactionRules(data.data)
    } catch (error) {
      createError('Something went wrong!')
    }
  },

  async deleteTransaction(id) {
    try {
      const { status } = await axios.delete(`/transaction-rules/${id}`)

      return status === 200
        ? createSuccessMsg('Transaction removed Successfully')
        : createError('Something went wrong!')
    } catch (err) {
      createError('Something went wrong!')
    }
  },

  async createTransactionRule(payload) {
    try {
      const { status } = await axios.post(`/transaction-rules/`, payload)

      return status === 200
        ? createSuccessMsg('Transaction added Successfully')
        : createError('Something went wrong!')
    } catch (err) {
      createError('Something went wrong!')
    }
  },

  async updateTransactionRule(payload) {
    try {
      const { status } = await axios.put(`/transaction-rules/${payload._id}`, payload)

      return status === 200
        ? createSuccessMsg('Transaction updated Successfully')
        : createError('Something went wrong!')
    } catch (err) {
      createError('Something went wrong!')
    }
  },

  async uploadFile(payload) {
    try {
      const data = new FormData()
      data.append('image', payload)

      const resp = await axios.post(`/s3-bucket/s3-file-upload`, data)

      return resp
    } catch (err) {
      createError('Something went wrong!')
    }
  },
}
