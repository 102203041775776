<template>
  <v-btn
    :class="icon ? 'pl-2 pr-4' : 'px-4'"
    :color="color"
    :outlined="outlined"
    :small="small"
    :text="text"
    :block="block"
    :x-small="xSmall"
    :href="href"
    :target="target"
    :disabled="disabled || loading"
    :loading="loading"
    @click.stop="$emit('click')"
  >
    <v-icon v-if="icon" size="18" class="me-1">
      {{ icon }}
    </v-icon>
    <span class="font-weight-medium" v-if="title">{{ title }}</span>
    <span class="font-weight-medium" v-else><slot></slot></span>
  </v-btn>
</template>

<script>
export default {
  name: 'AcButton',
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped></style>
