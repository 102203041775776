import companiesService from '@/services/companies/companiesService'

export default {
  namespaced: true,
  state: {
    companies: { totalRecords: 0, records: [] },
  },
  getters: {
    getCompanies: state => state.companies.records,
    getCompaniesTotal: state => state.companies.totalRecords,
  },
  mutations: {
    SET_COMPANIES(state, payload) {
      state.companies = payload
    },
  },
  actions: {
    async fetchCompanies({ commit }) {
      commit('SET_COMPANIES', { totalRecords: 0, records: [] })
      const data = await companiesService.fetchCompanies()
      console.log(data)
      commit('SET_COMPANIES', data)
    },
    async toggleCompanyActiveStatus({ commit }, payload) {
      const data = await companiesService.toggleCompanyActiveStatus(payload)
      return data
    },
    async createCompany({ commit }, payload) {
      const data = await companiesService.createCompany(payload)
      return data
    },
    async removeCompany({ commit }, payload) {
      const data = await companiesService.removeCompany(payload)
      return data
    },
    async updateCompanySubscription({ commit }, payload) {
      const data = await companiesService.updateCompanySubscription(payload)
      return data
    },
  },
}
