import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.disabled)?_c(VTooltip,{attrs:{"bottom":_vm.bottom,"max-width":"160px","disabled":_vm.noTooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.icon)?_c(VBtn,_vm._g(_vm._b({class:_vm.btnClass,attrs:{"fab":"","x-small":"","color":_vm.color,"href":_vm.href,"target":_vm.target,"loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.$emit('click')}}},'v-btn',attrs,false),on),[_c(VIcon,{attrs:{"size":_vm.iconSize}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1):_vm._e()]}}],null,false,3151706276)},[_c('span',{staticClass:"text-caption font-weight-bold"},[_vm._v(_vm._s(_vm.text))])]):_c(VTooltip,{attrs:{"bottom":_vm.bottom,"max-width":"160px","disabled":_vm.noTooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(_vm.icon)?_c(VBtn,{class:_vm.btnClass,attrs:{"fab":"","x-small":"","color":_vm.color,"disabled":""}},[_c(VIcon,{attrs:{"size":_vm.iconSize}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1):_vm._e()],1)]}}])},[_c('span',{staticClass:"text-caption font-weight-bold"},[_vm._v(_vm._s(_vm.text))])])}
var staticRenderFns = []

export { render, staticRenderFns }