import transformers from '@/transformers'
import axios from 'axios'

const { createError, createSuccessMsg } = transformers

export default {
  async authenticateUser(payload) {
    try {
      const { status } = await axios.put(`/aml-kyc/auth`, payload)

      return status === 200 ? createSuccessMsg('Success') : createError('Something went wrong!')
    } catch (error) {
      if (error.response) {
        const { data, status } = error.response
        if (status === 401) {
          return createError(data)
        } else {
          return createError('Something went wrong!')
        }
      }
    }
  },

  async checkPassword(payload) {
    try {
      const { status } = await axios.put(`/aml-kyc/check-password`, payload)

      return status === 200 ? createSuccessMsg('Success') : createError('Something went wrong!')
    } catch (error) {
      if (error.response) {
        const { data, status } = error.response
        if (status === 401) {
          return createError(data)
        } else {
          return createError('Something went wrong!')
        }
      }
    }
  },

  async fetchCustomers(payload) {
    try {
      const { companyId, params } = payload
      const { data } = await axios.get(`/aml-kyc/customers/${companyId}`, { params })

      const { docs, total } = data
      const response = { records: docs, totalRecords: total }

      return response
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
}
