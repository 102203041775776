const mapRestrictedWatchList = records => {
  if (records?.length) {
    return records.map(record => ({
      id: record._id,
      security: record.security,
      listType: record.listType,
      startDate: record.startDate === 'NA' ? '-' : record.startDate,
      expiryDate: record.expiryDate === 'NA' ? '-' : record.expiryDate,
      type: record.type,
      symbol: record.symbol,
      exchange: record.exchange,
    }))
  }
}

export default {
  mapRestrictedWatchList,
}
