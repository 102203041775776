import restrictedAndWatchListService from '@/services/paDealing/restrictedAndWatchListService'
import staffService from '@/services/paDealing/staffService'
import statementsService from '@/services/paDealing/statementsService'

export default {
  namespaced: true,
  state: {
    // * staff module
    staff: [],
    accounts: [],

    // * restricted list module
    restrictedList: {},
    security: [],

    // * statements module
    statements: { totalRecords: 0, records: [] },
    brokerStatements: { totalRecords: 0, records: [] },
  },
  getters: {
    companyStaff: state => state.staff,
    companyStaffAccounts: state => state.accounts,

    // * restricted list module
    getRestrictedList: state => state.restrictedList,
    securityList: state => state.security,

    // * statements module
    userOptions: state => [
      { id: 'All Users', name: 'All Users', isActive: true },
      ...state.staff.map(user => ({
        id: user.id,
        name: user.name,
        isActive: !user.isInActive,
      })),
    ],

    // * statements module
    getStatements: state => state.statements.records,
    getStatementsTotal: state => state.statements.totalRecords,
    getBrokerStatements: state => state.brokerStatements.records,
    getBrokerStatementsTotal: state => state.brokerStatements.totalRecords,
  },
  mutations: {
    // * staff module
    SET_STAFF(state, payload) {
      state.staff = payload
    },
    SET_ACCOUNTS(state, payload) {
      state.accounts = payload
    },
    REMOVE_STAFF(state, id) {
      const data = state.staff?.filter(staff => staff.id !== id)
      state.staff = data
    },
    REMOVE_ACCOUNT(state, id) {
      const data = state.accounts?.filter(accounts => accounts.id !== id)
      state.accounts = data
    },

    // * restricted list module
    SET_RESTRICTED_LIST(state, payload) {
      state.restrictedList = payload
    },
    REMOVE_RESTRICTED_ITEM(state, id) {
      const { records } = state.restrictedList
      const data = records?.filter(records => records.id !== id)
      state.restrictedList.records = data
    },
    SET_SECURITY_LIST(state, payload) {
      state.security = payload
    },

    // * statements module
    SET_STATEMENTS(state, payload) {
      state.statements = payload
    },

    SET_BROKER_STATEMENTS(state, payload) {
      state.brokerStatements = payload
    },
  },
  actions: {
    // * staff api
    async fetchCompanyStaff({ commit }, companyID) {
      commit('SET_STAFF', [])
      const data = await staffService.fetchCompanyStaff(companyID)
      commit('SET_STAFF', data)
    },
    async createStaff({ commit }, payload) {
      const response = await staffService.createStaff(payload)
      return response
    },
    async updateApprover(storeContext, payload) {
      const response = await staffService.updateApprover(payload)
      return response
    },
    async removeCompanyStaff(storeContext, staffID) {
      const response = await staffService.removeStaffById(staffID)
      return response
    },

    // * account number api
    async fetchAccounts({ commit }, companyID) {
      commit('SET_ACCOUNTS', [])
      const data = await staffService.fetchAccounts(companyID)
      commit('SET_ACCOUNTS', data)
    },
    async createAccountNumber(storeContext, payload) {
      const response = await staffService.createAccountNumber(payload)
      return response
    },
    async updateAccountNumber(storeContext, payload) {
      const response = await staffService.updateAccountNumber(payload)
      return response
    },
    async removeAccountNumber(storeContext, id) {
      const response = await staffService.removeAccountNumber(id)
      return response
    },
    async accountsUploadFile(storeContext, payload) {
      const response = await staffService.accountsUploadFile(payload)
      return response
    },
    async accountsRemoveFile(storeContext, payload) {
      const response = await staffService.accountsRemoveFile(payload)
      return response
    },

    // * restricted list module
    async fetchRestrictedList({ commit }, payload) {
      commit('SET_RESTRICTED_LIST', {})
      const data = await restrictedAndWatchListService.fetchRestrictedList(payload)
      commit('SET_RESTRICTED_LIST', data)
    },
    async fetchSecurityList({ commit }, query) {
      commit('SET_SECURITY_LIST', [])
      const { success, data } = await restrictedAndWatchListService.fetchSecurityList(query)
      if (success) {
        commit('SET_SECURITY_LIST', data)
      } else {
        return data
      }
    },
    async removeRestrictedListRecord(storeContext, id) {
      const response = await restrictedAndWatchListService.removeRestrictedListRecord(id)
      return response
    },
    async createRestrictedListItem(storeContext, payload) {
      const response = await restrictedAndWatchListService.createRestrictedListItem(payload)
      return response
    },
    async updateRestrictedListItem(storeContext, payload) {
      const response = await restrictedAndWatchListService.updateRestrictedListItem(payload)
      return response
    },

    // * watch list module
    async removeWatchListRecord(storeContext, id) {
      const response = await restrictedAndWatchListService.removeWatchListRecord(id)
      return response
    },
    async createWatchListItem(storeContext, payload) {
      const response = await restrictedAndWatchListService.createWatchListItem(payload)
      return response
    },
    async updateWatchListItem(storeContext, payload) {
      const response = await restrictedAndWatchListService.updateWatchListItem(payload)
      return response
    },

    // * statements module
    async fetchStatements({ commit }, { companyID, payload }) {
      commit('SET_STATEMENTS', { totalRecords: 0, records: [] })
      const { totalRecords, records } = await statementsService.fetchStatements(companyID, payload)
      commit('SET_STATEMENTS', { totalRecords, records })
    },

    async createStatement(storeContext, payload) {
      const records = await statementsService.createStatement(payload)
      return records
    },

    async removeStatement(storeContext, id) {
      const response = await statementsService.removeStatement(id)
      return response
    },

    async fetchBrokerStatements({ commit }, companyID) {
      commit('SET_BROKER_STATEMENTS', { totalRecords: 0, records: [] })
      const { totalRecords, records } = await statementsService.fetchBrokerStatements(companyID)
      commit('SET_BROKER_STATEMENTS', { totalRecords, records })
    },

    async updateStatement({ commit }, payload) {
      const res = await statementsService.updateStatement(payload)
      return res
    },
  },
}
