import transformers from '@/transformers'
import axios from 'axios'

const { createError, createSuccessMsg, mapTransactionRules } = transformers

export default {
  async fetchStaffTransactions(payload) {
    try {
      const {
        brokerId,
        getRatifiedList,
        company,
        limit,
        pagination,
        searchQuery,
        selectedType,
        showInactiveUsers,
        skipData,
        year,
        ratifiedBy,
      } = payload

      const params = {
        pagination,
        limit,
        skipData,
        year,
        showInactiveUsers,
        searchQuery,
        brokerId,
        selectedType,
        getRatifiedList,
        ratifiedBy,
      }
      const data = await axios.get(`/transactions/staff/${company}`, {
        params,
      })

      return data
    } catch (error) {
      createError('Something went wrong!')
    }
  },

  async removeTransaction(id) {
    try {
      const { status } = await axios.delete(`/transactions/staff/${id}`)
      return status === 200
        ? createSuccessMsg('transaction removed Successfully')
        : createError('Something went wrong!')
    } catch (err) {
      return createError('Something went wrong!')
    }
  },

  async downloadStaff(payload) {
    try {
      const { year, users, startMonth, endMonth, company } = payload
      const params = {
        year,
        users,
        startMonth,
        endMonth,
      }

      const { status, data } = await axios.get(`/transactions/ratified/${company}`, {
        params,
      })

      const updatedUrl = data.replace('http', 'https')
      window.open(data, '_blank')
      return status === 200
        ? createSuccessMsg('Please check your download folder')
        : createError('Something went wrong!')
    } catch (err) {
      return createError('Something went wrong!')
    }
  },

  async addTransactionStaff(payload) {
    try {
      const transactionsArr = [payload]
      const data = { transactionsArr }
      const { status } = await axios.post(`/transactions/staff/`, data)

      return status === 200
        ? createSuccessMsg('Successfully saved new transaction')
        : createError('Something went wrong!')
    } catch (error) {
      createError('Something went wrong!')
    }
  },
}
