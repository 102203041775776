import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-4"},[_c(VCardTitle,[_c('span',[_vm._t("default")],2),_c(VMenu,{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ml-auto"},on),[_vm._v(" Logout "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-menu-down")])],1)]}}])},[_c(VList,[_c(VListItem,{staticClass:"dropdown-item",on:{"click":_vm.navigateToProfile}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"icon":_vm.icons.mdiPlus}})],1),_c(VListItemTitle,[_vm._v("Personal Profile")])],1),_c(VListItem,{staticClass:"dropdown-item",on:{"click":_vm.logout}},[_c(VListItemIcon,[_c(VIcon,{attrs:{"icon":_vm.icons.mdiPlus}})],1),_c(VListItemTitle,[_vm._v("Logout")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }