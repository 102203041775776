import transformers from '@/transformers'
import axios from '@axios'
const { createError, createSuccessResponse, createSuccessMsg } = transformers

export default {
  // * restricted list module
  async fetchRestrictedList(payload) {
    try {
      const { companyID, skipData, limit } = payload
      const { data } = await axios.get(
        `/restricted-watch-list/${companyID}?skipData=${skipData}&limit=${limit}`,
      )
      const { docs, totalItems } = data
      return { records: transformers.mapRestrictedWatchList(docs), totalItems }
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async removeRestrictedListRecord(id) {
    try {
      const { status } = await axios.delete(`/restricted-list/${id}`)
      return status === 200
        ? createSuccessMsg('Entry Removed Successfully')
        : createError(`${status}  Something went wrong!`)
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async createRestrictedListItem(payload) {
    try {
      console.log({ payload })
      const { companyID, content } = payload
      const { status } = await axios.post(`/restricted-list/${companyID}`, content)
      return status === 200
        ? createSuccessMsg('Item added Successfully')
        : createError('Something went wrong!')
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async updateRestrictedListItem(payload) {
    try {
      const { recordID, content } = payload
      const { status } = await axios.put(`/restricted-list/${recordID}`, content)
      return status === 200
        ? createSuccessMsg('Item added Successfully')
        : createError('Something went wrong!')
    } catch (error) {
      return createError('Something went wrong!')
    }
  },

  // * watch list module
  async removeWatchListRecord(id) {
    try {
      const { status } = await axios.delete(`/watch-list/${id}`)
      return status === 200
        ? createSuccessMsg('Entry Removed Successfully')
        : createError(`${status}  Something went wrong!`)
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async createWatchListItem(payload) {
    try {
      const { companyID, content } = payload
      const { status } = await axios.post(`/watch-list/${companyID}`, content)
      return status === 200
        ? createSuccessMsg('Item added Successfully')
        : createError('Something went wrong!')
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async updateWatchListItem(payload) {
    try {
      const { recordID, content } = payload
      const { status } = await axios.put(`/watch-list/${recordID}`, content)
      return status === 200
        ? createSuccessMsg('Item added Successfully')
        : createError('Something went wrong!')
    } catch (error) {
      return createError('Something went wrong!')
    }
  },

  // ? for upsertModal
  async fetchSecurityList(query) {
    try {
      const { data } = await axios.get('/security-autocomplete', {
        params: {
          q: query,
        },
      })
      return createSuccessResponse(data)
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
}
