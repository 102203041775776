import amlKycService from '@/services/amlKyc/amlKycService'

export default {
  namespaced: true,
  state: {
    auth: '',
    customers: { totalRecords: 0, records: [] },
  },

  getters: {
    getAuth: state => state.auth,
    getCustomers: state => state.customers.records,
    getCustomersTotal: state => state.customers.totalRecords,
  },

  mutations: {
    SET_CUSTOMERS(state, payload) {
      state.customers = payload
    },
  },

  actions: {
    async authenticateUser({ commit }, password) {
      const data = await amlKycService.authenticateUser(password)
      return data
    },
    async checkPassword({ commit }, payload) {
      const data = await amlKycService.checkPassword(payload)
      return data
    },
    async fetchCustomers({ commit }, payload) {
      console.log(payload)
      commit('SET_CUSTOMERS', { totalRecords: 0, records: [] })
      const response = await amlKycService.fetchCustomers(payload)
      commit('SET_CUSTOMERS', response)
    },
  },
}
