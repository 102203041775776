import moment from 'moment'

const formatDate = (date, format = 'DD MMM YYYY') => moment(date).format(format)

const mapTransactionRules = (transactions = []) => {
  return transactions.map(
    ({
      _id,
      attachment,
      createdAt,
      createdBy,
      date,
      effectiveDate,
      logs,
      status,
      summary,
      type,
      updatedAt,
    }) => ({
      id: _id,
      attachment,
      createdAt: formatDate(createdAt, 'DD MMM YYYY HH:mm:ss'),
      createdBy,
      date,
      effectiveDate: formatDate(effectiveDate) || formatDate(Date.now()),
      logs,
      status,
      summary,
      type,
      updatedAt,
    }),
  )
}

export default {
  mapTransactionRules,
}
