import appService from '@/services/app.js'

export default {
  namespaced: true,
  state: {
    initialLoader: false,
    shallContentShowOverlay: false,
    user: null,
    companiesOptions: [],
    selectedCompany: null,
  },
  getters: {
    loading: state => state.initialLoader,
    loggedInUser: state => state.user,
    companies: state => state.companiesOptions,
    selectedCompany: state => state.selectedCompany,
  },
  mutations: {
    SET_LOADING(state, payload) {
      state.initialLoader = payload
    },
    SET_USER(state, payload) {
      state.user = payload
    },
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },
    SET_COMPANIES_OPTIONS(state, payload) {
      state.companiesOptions = payload
    },
    SET_SELECTED_COMPANY(state, payload) {
      state.selectedCompany = payload
    },
  },
  actions: {
    async login({ commit }, payload) {
      const response = await appService.login(payload)

      if (response.success) {
        commit('SET_USER', response.user)
        localStorage.setItem('accessToken', response.token)

        return response
      } else {
        return response
      }
    },
    async fetchCompaniesOptions({ commit }) {
      commit('SET_LOADING', true)
      commit('SET_COMPANIES_OPTIONS', [])

      const data = await appService.fetchCompaniesOptions()

      commit('SET_COMPANIES_OPTIONS', data)
      commit('SET_SELECTED_COMPANY', data[0].id)
      commit('SET_LOADING', false)
    },
  },
}
