import amlKyc from './modules/aml-kyc'
// import compliance from './modules/compliance-report'
// import crsFatca from './modules/crs-fatca'
import companies from './modules/companies'
import paDealing from './modules/pa-dealing'

export default [
  ...companies,
  ...amlKyc,
  ...paDealing,
  // ...AML, ...compliance, ...training, ...crsFatca
]
