const mapCompanyStaff = staff => {
  if (staff?.length) {
    return staff.map(member => ({
      id: member._id,
      name: `${member.user.firstname || ''} ${member.user.surname || ''}`,
      email: member.user.email,
      firstname: member.user.firstname || '',
      surname: member.user.surname,
      role: member.user.approverUser ? 'Approver' : '-',
      role: member.user.approverUser ? 'Approver' : '-',
      license: member.user.licenses.map(i => i.type).join() || '-',
      isInActive: member.user.deactivated || false,
      approver: member.user.approverUser || false,
      companyID: member.user.company._id,
    }))
  }
}
const mapAccounts = accounts => {
  if (accounts?.length) {
    return accounts.map(account => ({
      id: account._id,
      userID: account.userId?.id,
      isInActive: account.userId?.user.deactivated || false,
      userName: `${account.userId?.user.firstname || ''} ${account.userId?.user.surname || ''}`,
      broker: account.broker,
      holderName: account.holderName || '-',
      accountNumber: account.number,
      accountType: account.accountType || '-',
      status: account.closingDate ? 'Closed' : 'Active',
      statementRequired: account.statementRequired,
      activeDate: account.activeSince,
      closeDate: account.closingDate,
      notes: account.notes,
      createdBy: `${account.createdBy?.user.firstname || ''} ${
        account.createdBy?.user.surname || ''
      }`,
      createdId: account.createdBy?.id || '',
      createdDate: account.createdDate,
      editedBy: `${account.editedBy?.user.firstname || ''} ${account.editedBy?.user.surname || ''}`,
      editedDate: account.editedDate,
    }))
  }
}

export default {
  mapCompanyStaff,
  mapAccounts,
}
