<template>
  <component :is="resolveLayoutVariant" :class="`skin-variant--${appSkinVariant}`">
    <transition v-if="!loading" :name="appRouteTransition" mode="out-in" appear>
      <router-view></router-view>
    </transition>
    <transition v-else :name="appRouteTransition" mode="out-in" appear>
      <div class="h-full d-flex align-center justify-center">
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
    </transition>
    <ac-notification ref="ac-notification" />
  </component>
</template>

<script>
// Layouts
import LayoutBlank from '@/layouts/blank/LayoutBlank.vue'
import LayoutContentVerticalNav from '@/layouts/content/LayoutContentVerticalNav.vue'
import { useLayout } from '@core/layouts/composable/useLayout'
// Dynamic vh
import useDynamicVh from '@core/utils/useDynamicVh'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    LayoutContentVerticalNav,
    LayoutBlank,
  },
  data() {
    return {
      resolveLayoutVariant: 'layout-blank',
      appSkinVariant: 'bordered',
      appRouteTransition: 'scroll-x-transition',
    }
  },
  computed: {
    ...mapGetters('app', ['loading']),
  },
  methods: {
    ...mapActions('app', ['fetchCompaniesOptions']),
  },
  watch: {
    $route: {
      async handler(val) {
        this.resolveLayoutVariant =
          val.meta.layout === 'blank' ? 'layout-blank' : 'layout-content-vertical-nav'
      },
    },
    resolveLayoutVariant: {
      immediate: true,
      async handler(val) {
        if (val === 'layout-content-vertical-nav') await this.fetchCompaniesOptions()
      },
    },
  },
  setup() {
    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()
    useDynamicVh()
  },
}
</script>
