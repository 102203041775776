const companies = [
  {
    path: '/companies',
    name: 'companies',
    component: () => import('@/views/companies/Companies.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default companies
