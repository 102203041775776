<template>
  <v-card class="mb-4">
    <v-card-title>
      <span>
        <slot></slot>
      </span>
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" class="ml-auto">
            Logout
            <v-icon right>mdi-menu-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="navigateToProfile" class="dropdown-item">
            <v-list-item-icon>
              <v-icon :icon="icons.mdiPlus"></v-icon>
            </v-list-item-icon>
            <v-list-item-title>Personal Profile</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout" class="dropdown-item">
            <v-list-item-icon>
              <v-icon :icon="icons.mdiPlus"></v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
  </v-card>
</template>

<script>
import { mdiDeleteOutline, mdiDownload, mdiPlus, mdiLogout } from '@mdi/js'
import router from '@/router'

export default {
  name: 'ModuleHeading',
  data: () => ({
    icons: {
      mdiPlus,
      mdiDeleteOutline,
      mdiDownload,
      mdiLogout,
    },
  }),
  methods: {
    logout() {
      localStorage.removeItem('accessToken')
      // Redirect to login page
      router.push({ name: 'auth-login' })
    },

    navigateToProfile() {},
  },
}
</script>
<style lang="scss" scoped>
.dropdown-item {
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #e0e0e0;
}
</style>
