// ! fix: file name should be approvalsService.js
// ! fix: Transformers import should be specific and variable name should be lower-case (ref: paRequestService.js)
import Transformers from '@/transformers'
import axios from '@axios'
import moment from 'moment-timezone'
// ! fix: remove unused code
const { createError, createSuccessResponse, createSuccessMsg } = Transformers

export default {
  // ! fix: update service methods name according to reference service (ref: paRequestService.js)
  async getApproval(payload) {
    try {
      const params = {
        limit: payload.limit || 10,
        skipData: payload.skipData || 0,
        showInactiveUsers: payload.showInactiveUsers || false,
        search: payload.search,
      }
      const { data } = await axios.get(`/approvals/${payload.companyId}`, { params })
      return Transformers.approvalListTransformer(data)
    } catch (error) {
      createError('Something went wrong!')
    }
  },
  async downloadApproval(payload) {
    try {
      const params = {
        endMonth: payload.endMonth,
        startMonth: payload.startMonth,
        users: payload.users,
      }
      const { status, data } = await axios.get(`approvals/download-pdf/${payload.company}`, {
        params,
      })
      const downloadUrl = data[0]
      const updatedUrl = downloadUrl.replace('http', 'https')

      window.open(updatedUrl, '_blank')
      return status === 200
        ? createSuccessMsg('Please check your download folder')
        : createError('Something went wrong!')
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async removeApproval(id) {
    try {
      const { status } = await axios.delete(`/approvals/${id}`)
      return status === 200
        ? createSuccessMsg('Approval removed Successfully')
        : createError('Something went wrong!')
    } catch (err) {
      return createError('Something went wrong!')
    }
  },

  // ! duplicate service method (ref: staffService.js) (later)
  async getAccountNumbers(companyId) {
    try {
      const { data } = await axios.get(`/staff/account-numbers/${companyId}`)
      return Transformers.mapAccountNumbers(data)
    } catch (err) {
      createError('Something went wrong!')
    }
  },

  async createApproval(payload) {
    try {
      const date = moment(payload.joinDate).format('DD MMM YYYY')
      const body = {
        security: payload.security,
        exchange: payload.exchange,
        symbol: payload.symbol,
        user: payload.user,
        accountNumber: payload.accountNumber,
        details: payload.details,
        investmentClass: payload.investmentClass,
        gtc: payload.gtc,
        approver: payload.approver,
        approvalDate: date,
        broker: payload.broker,
      }

      const { status } = await axios.post(`/approvals/${payload.selectedCompany}`, body)
      return status === 200
        ? createSuccessMsg('Item added Successfully')
        : createError('Something went wrong!')
    } catch (err) {
      return createError('Something went wrong!')
    }
  },

  async updateApproval(payload) {
    try {
      const date = moment(payload.joinDate).format('DD MMM YYYY')
      const body = {
        security: payload.security,
        exchange: payload.exchange,
        symbol: payload.symbol,
        user: payload.user,
        accountNumber: payload.accountNumber,
        details: payload.details,
        investmentClass: payload.investmentClass,
        gtc: payload.gtc,
        approver: payload.approver,
        approvalDate: date,
        broker: payload.broker,
      }

      const { status } = await axios.put(`/approvals/${payload.id}`, body)
      return status === 200
        ? createSuccessMsg('Item updated Successfully')
        : createError('Something went wrong!')
    } catch (err) {
      return createError('Something went wrong!')
    }
  },
}
