const mapStatements = records => {
  if (records?.length) {
    return records.map(record => ({
      id: record._id,
      name: record.name,
      author: record.author,
      date: record.date,
      createdDate: record.createdDate,
      broker: record.broker,
      link: record.link,
    }))
  }
}

const mapBrokerStatements = records => {
  if (records?.docs.length) {
    records.docs.map(record => console.log(record))
    // return records.docs.map(record => ({
    //   id: record._id,
    //   accountType: record.accountType,
    //   activeSince: moment(record.activeSince).format('DD MMM YYYY'),
    //   broker: record.broker,
    //   closingDate: record.closingDate,
    //   createdDate: record.createdDate,
    //   editedBy: record.editedBy,
    //   editedDate: record.editedDate,
    //   files: record.files,
    //   holderName: record.holderName,
    //   notes: record.notes,
    //   number: record.accountNumber,
    //   statementRequired: record.statementRequired,
    //   userId: record.userId,
    //   userName: record.userName,
    //   yearlyRecord: record.2023
    // }))
  }
}

export default {
  mapStatements,
  mapBrokerStatements,
}
