const mapDownloadPaRequests = records => {
  if (records?.length) {
    return records.map(record => ({
      id: record._id,
      username: record.username,
      date: record.date,
      tradeType: record.rTypeOfTrade,
      type: record.buysell,
      gtc: record.gtc,
      quantity: record.quantity,
      security: record.security,
      status: record.status,
      requestorComment: record.approval.initial.message || '',
      approverComment: record.details.requestorComment || '',
    }))
  } else {
    return []
  }
}
const mapPaRequestRecords = records => {
  if (records?.length) {
    return records.map(record => {
      const details = record.details
      const settings = record.settings
      return {
        id: record._id,
        username: record.username,
        date: record.date,
        tradeType: record.rTypeOfTrade,
        type: record.buysell,
        gtc: record.gtc,
        security: record.security,
        status: record.status,
        details: {
          name: details.name,
          requestDate: record.date,
          requestAction: details.details,
          security: details.security,
          ticker: details.symbol,
          exchange: details.exchange,
          quantity: details.numOfShares,
          gtcOrder: details.gtc,
          investmentClass: details.investmentClass,
          accInfo: details.accountNumber,
          broker: details.broker,
          selectedInitialApprover: {
            id: details.approverId,
            name: details.approver || details.approverName,
          },
          subsequentApprovers: details.subsequentPAApprover,
          requestorComment: details.requestorComment,
          cancelRequest: details.cancelRequest,
        },
        declaration: settings.declaration,
        approval: record.approval,
        company: record.company,
      }
    })
  } else {
    return []
  }
}

export default {
  mapPaRequestRecords,
  mapDownloadPaRequests,
}
