/**
 * Creates a success response object with a 'success' flag set to true and a given data payload.
 * @param {*} data - The data payload to include in the success response.
 * @returns {Object} - An object with a 'success' property set to true and a 'data' property with the provided data.
 */
const createSuccessResponse = data => {
  return {
    success: true,
    data,
  }
}

/**
 * Creates a success response object with a 'success' flag set to true and a given success message.
 * @param {string} message - The success message to include in the success response.
 * @returns {Object} - An object with a 'success' property set to true and a 'message' property with the provided message.
 */
const createSuccessMsg = message => {
  return {
    success: true,
    message,
  }
}

/**
 * Creates an error response object with a 'success' flag set to false and a given error message.
 * @param {string} message - The error message to include in the error response.
 * @returns {Object} - An object with a 'success' property set to false and a 'message' property with the provided message.
 */
const createError = message => {
  return {
    success: false,
    message,
  }
}
const mapCompaniesOptions = companies => {
  if (companies?.length) {
    return companies.map(company => ({
      id: company._id,
      name: company.name,
      isActive: !company.deactivated,
    }))
  }
}

export default {
  createSuccessResponse,
  createSuccessMsg,
  createError,
  mapCompaniesOptions,
}
