import transactionRuleService from '@/services/transactionRules/transactionRuleService'

export default {
  namespaced: true,
  state: {
    transactionRules: [],
    url: '',
  },
  getters: {
    getTransactionRule: state => state.transactionRules,
    getUrl: state => state.url,
  },
  mutations: {
    SET_TRANSACTIONS(state, payload) {
      state.transactionRules = payload
    },
    SET_URL(state, payload) {
      state.url = payload
    },
  },
  actions: {
    async fetchTransactions({ commit }, companyId) {
      commit('SET_TRANSACTIONS', [])
      const data = await transactionRuleService.fetchTransactions(companyId)
      commit('SET_TRANSACTIONS', data)
    },

    async deleteTransaction({ commit }, id) {
      const response = await transactionRuleService.deleteTransaction(id)
      return response
    },

    async createTransactionRule({ commit }, payload) {
      const response = await transactionRuleService.createTransactionRule(payload)
      return response
    },

    async updateTransactionRule({ commit }, payload) {
      const response = await transactionRuleService.updateTransactionRule(payload)
      return response
    },

    async uploadFile({ commit }, payload) {
      commit('SET_URL', '')
      const response = await transactionRuleService.uploadFile(payload)
      commit('SET_URL', response.body)
    },
  },
}
