import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'

import Vue from 'vue'
import Vuex from 'vuex'

import amlKyc from './modules/aml-kyc'
import app from './modules/app'
import approval from './modules/approvals'
import companies from './modules/companies'
import paDealing from './modules/pa-dealing'
import paRequest from './modules/pa-request'
import portfolio from './modules/transaction-portfolio'
import transactionRules from './modules/transaction-rules'
import transactionStaff from './modules/transactions-staff'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    appConfig: appConfigStoreModule,
    app,
    paDealing,
    approval,
    paRequest,
    transactionRules,
    transactionStaff,
    companies,
    amlKyc,
    portfolio,
  },
})
