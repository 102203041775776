import transformers from '@/transformers'
import axios from '@axios'
const { createError, createSuccessMsg } = transformers

export default {
  async fetchStatements(companyID, payload) {
    try {
      const { data } = await axios.get(`/statements/${companyID}`, { params: payload })
      const { totalItems: totalRecords, docs } = data
      return { totalRecords, records: transformers.mapStatements(docs) }
    } catch (error) {}
  },
  async removeStatement(id) {
    try {
      const { status } = await axios.delete(`/statements/${id}`)
      return status === 200
        ? createSuccessMsg('Entry Removed Successfully')
        : createError(`${status}  Something went wrong!`)
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async createStatement(payload) {
    try {
      const { status } = await axios.post(`/statements`, payload)
      return status === 200
        ? createSuccessMsg('Statement Added Successfully')
        : createError(`${status}  Something went wrong!`)
    } catch (error) {
      return createError('Something went wrong!')
    }
  },
  async fetchBrokerStatements(payloadData) {
    try {
      const { user, companyId, year, showInactiveUsers, statementOnly, skipData, limit } =
        payloadData

      const payload = {
        showInactiveUsers: showInactiveUsers,
        user: user,
        year: year,
        statementOnly: statementOnly,
        skipData: skipData,
        limit: limit,
      }

      const { data } = await axios.get(`/statements/${companyId}`, { params: payload })

      const { totalItems: totalRecords, docs } = data
      return { totalRecords, records: docs }
    } catch (error) {
      return createError('Something went wrong!')
    }
  },

  async updateStatement(payloadData) {
    try {
      const { _id } = payloadData

      const res = await axios.put(`/statements/${_id}`, payloadData)
      return res
    } catch (error) {
      console.log(error)
      return createError('Something went wrong!')
    }
  },
}
