import staffService from '@/services/paDealing/staffService'
import transactionRuleService from '@/services/transactionStaff/transactionStaffService'

export default {
  namespaced: true,
  state: {
    transactionStaff: { totalItems: 0, docs: [], ratifiedByUsers: {} },
    accounts: [],
  },
  getters: {
    getTransactionStaff: state => state.transactionStaff.docs,
    getTransactionCount: state => state.transactionStaff.totalItems,
    getRatifiedUsers: state => state.transactionStaff.ratifiedByUsers,
    companyStaffAccounts: state => state.accounts,
  },
  mutations: {
    SET_STAFF_TRANSACTIONS(state, payload) {
      state.transactionStaff = payload
    },
    SET_ACCOUNTS(state, payload) {
      state.accounts = payload
    },
  },
  actions: {
    async removeTransaction(state, id) {
      const response = await transactionRuleService.removeTransaction(id)
      return response
    },

    async fetchStaffTransactions({ commit }, payload) {
      commit('SET_STAFF_TRANSACTIONS', { totalItems: 0, docs: [], ratifiedByUsers: {} })
      const staffData = await transactionRuleService.fetchStaffTransactions(payload)
      const { docs, totalItems, ratifiedByUsers } = staffData.body
      commit('SET_STAFF_TRANSACTIONS', { totalItems, docs, ratifiedByUsers })
    },

    async downloadStaff({ commit }, payload) {
      const staffData = await transactionRuleService.downloadStaff(payload)
      return staffData
    },

    async fetchAccounts({ commit }, companyID) {
      commit('SET_ACCOUNTS', [])
      const data = await staffService.fetchAccounts(companyID)
      commit('SET_ACCOUNTS', data)
    },

    async addTransactionStaff({ commit }, companyID) {
      const data = await transactionRuleService.addTransactionStaff(companyID)
      return data
    },
  },
}
