const crsFatca = [
  {
    path: '/crs',
    name: 'crs',
    component: () => import('@/views/crs-fatca/CRS.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/fatca',
    name: 'fatca',
    component: () => import('@/views/crs-fatca/FATCA.vue'),
    meta: {
      layout: 'content',
    },
  },
]

//Statements

export default crsFatca
