const mapAccountNumbers = numbers => {
  if (numbers?.length) {
    return numbers.map(data => ({
      id: data._id,
      name: data.number,
      user_id: data.userId,
      broker: data.broker,
    }))
  }
}

export default {
  mapAccountNumbers,
}
