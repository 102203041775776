import moment from 'moment'

// ! fix: transformer name should be descriptive and specific
const approvalListTransformer = records => {
  if (records.docs.length) {
    const list = records.docs.map(record => ({
      id: record._id,
      userName: record.fullUserName,
      accountNumber: record.accountNumber,
      broker: record.broker,
      approver: record.approver.user.firstname + ' ' + record.approver.user.surname || '',
      security: record.security,
      gtcOrder: record.gtc,
      buyOrSell: record.details,
      investmentClass: record.investmentClass || '',
      ticker: record.symbol || '',
      exchange: record.exchange,
      approvalDate: moment(record.approvalDate).format('DD MMM YYYY'),
      match: record.match || 'No',
      settings: record.settings,
    }))
    return { totalLength: records.totalItems || '0', data: list }
  } else {
    return []
  }
}

export default {
  approvalListTransformer,
}
