import transformers from '@/transformers'
import axios from '@axios'

const { createError } = transformers

export default {
  async fetchPortfolio(companyID) {
    try {
      const { data } = await axios.get(`/transactions/portfolio/${companyID}`)
      return data
    } catch (error) {
      console.error(error)
      return createError('Something went wrong!')
    }
  },
}
