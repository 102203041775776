export default {
  methods: {
    // * to check if object is not empty
    checkObject(obj = {}) {
      if (obj) {
        return !!Object.keys(obj).length
      } else {
        return false
      }
    },
    // * for AcNotification
    AcNotify({ type, message, position, contentClass }) {
      if (this.$root.$children[0]?.$refs['ac-notification']) {
        this.$root.$children[0]?.$refs['ac-notification'].showAlert({
          type,
          message,
          position,
          contentClass,
        })
      }
    },
    // * to for names/fields with undefined in the string
    removeUndefinedFromString(str) {
      return str ? str.replace(/undefined/g, '') : 'N/A'
    },
  },
}
