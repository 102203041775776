const training = [
  {
    path: '/training/available-videos',
    name: 'training-available-videos',
    component: () => import('@/views/training/AvailableVideos.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/training/completed-videos',
    name: 'training-completed-videos',
    component: () => import('@/views/training/CompletedVideos.vue'),
    meta: {
      layout: 'content',
    },
  },
]

//Statements

export default training
