import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.canViewVerticalNavMenuLink(_vm.item))?_c(VListItem,_vm._b({staticClass:"vertical-nav-menu-link",class:{ 'bg-gradient-primary white--text': _vm.isActive },attrs:{"active-class":"bg-gradient-primary"}},'v-list-item',_vm.linkProps,false),[_c(VListItemIcon,[_c(VIcon,{class:{ 'alternate-icon-small': !_vm.item.icon },attrs:{"color":_vm.isActive ? 'white' : null}},[_vm._v(" "+_vm._s(_vm.item.icon || _vm.alternateIcon)+" ")])],1),_c(VListItemTitle,{staticClass:"text-sm font-weight-medium",class:{ 'white--text': _vm.isActive }},[_vm._v(" "+_vm._s(_vm.t(_vm.item.title))+" ")]),(_vm.item.badge)?_c(VListItemAction,{staticClass:"flex-shrink-0"},[_c(VBadge,{attrs:{"color":_vm.item.badgeColor,"inline":"","content":_vm.item.badge}})],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }