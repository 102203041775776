import '@/@fake-db/db'
// * global components
import AcLabel from '@/components/AcLabel.vue'
import AcModuleHeading from '@/components/AcModuleHeading.vue'
import AcNotification from '@/components/AcNotification.vue'
import { i18n } from '@/plugins/i18n'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import './plugins/acl'
import exportCSV from './plugins/exportCSV'
import helpers from './plugins/helpers'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

const components = {
  AcModuleHeading,
  AcLabel,
  AcNotification,
}
Object.entries(components).forEach(([name, component]) => {
  Vue.component(name, component)
})

Vue.mixin(exportCSV)
Vue.mixin(helpers)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  i18n,
  vuetify,

  render: h => h(App),
}).$mount('#app')
