<template>
  <div class="bg-gradient-primary-2 pa-2 white--text font-weight-bold">
    <slot> </slot>
  </div>
</template>

<script>
export default {
  name: 'AcLabel',
}
</script>

<style lang="scss" scoped></style>
