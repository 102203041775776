const padealing = [
  {
    path: '/pa-dealing/staff-list',
    name: 'pa-dealing-staff-list',
    component: () => import('@/views/pa-dealing/Staff.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/pa-dealing/policies',
    name: 'pa-dealing-policies',
    component: () => import('@/views/pa-dealing/Policies.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/pa-dealing/restricted-list',
    name: 'pa-dealing-restricted-list',
    component: () => import('@/views/pa-dealing/RestrictedList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/pa-dealing/statements',
    name: 'pa-dealing-statements',
    component: () => import('@/views/pa-dealing/Statements.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/pa-dealing/approvals',
    name: 'pa-dealing-approvals',
    component: () => import('@/views/pa-dealing/Approvals.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/pa-dealing/transactions/staff',
    name: 'pa-dealing-transactions-staff',
    component: () => import('@/views/pa-dealing/TransactionsStaff.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/pa-dealing/transactions/portfolio',
    name: 'pa-dealing-transactions-portfolio',
    component: () => import('@/views/pa-dealing/TransactionsPortfolio.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/pa-dealing/transactions/analysis',
    name: 'pa-dealing-transactions-analysis',
    component: () => import('@/views/pa-dealing/TransactionsAnalysis.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/pa-dealing/transaction-rules',
    name: 'pa-dealing-transaction-rules',
    component: () => import('@/views/pa-dealing/TransactionRules.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/pa-dealing/pa-requests',
    name: 'pa-dealing-pa-requests',
    component: () => import('@/views/pa-dealing/PARequests.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/pa-dealing/pa-reports',
    name: 'pa-dealing-pa-reports',
    component: () => import('@/views/pa-dealing/PAReports.vue'),
    meta: {
      layout: 'content',
    },
  },
]

//Statements

export default padealing
